import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import gql from "fraql";
import get from "lodash/get";
import { useTenantPicker } from "../TenantPickerContext";

export const ThemeContext = React.createContext(null);
export const useTheme = () => useContext(ThemeContext);

export const GET_TENANT_THEME = gql`
  query Get_Tenant_Theme($tenantId: String!) {
    TenantTheme_by_pk(id: $tenantId) {
      siteName
      loginPretext
      termsConditionsAcceptanceStatement
      FileLoginLogo {
        id
        url
      }
      FileLogo {
        id
        url
      }
      FileEventListBanner {
        id
        url
      }
      FileEventBanner {
        id
        url
      }
      FilePrivacyPolicy {
        id
        url
      }
      FileSportMeetBanner {
        id
        url
      }
      FileTermsAndConditions {
        id
        url
      }
    }
  }
`;

const GENERIC_FUSESPORT_THEME = {
  logo_path: `${window.location.origin}/tenant/fusesport/fusesport.png`,
  login_logo_path: `${window.location.origin}/tenant/fusesport/fusesport.png`,
  siteName: "Fusesport",
  loginPretext: "",
  termsConditionsAcceptanceStatement:
    "By using this site, you agree to the Terms and Conditions and Privacy Policy (as per the links at the top of the page).",
  cmEventListBanner: `${window.location.origin}/tenant/fusesport/event-list-banner.jpg`,
  cmEventBanner: `${window.location.origin}/tenant/fusesport/event-detail-banner.jpg`,
  cmSportMeetBanner: `${window.location.origin}/tenant/fusesport/sportMeet-detail-banner.jpg`,
  privacyPageLink: "https://www.fusesport.com/privacy-policy/",
  termsPageLink: "https://www.fusesport.com/terms/",
};

export function ThemeContextProvider({ children }) {
  const [theme, setTheme] = useState(null);
  const { tenantId } = useTenantPicker();

  // Load Tenant Theme, but only once the TenantID lookup has finished
  const { loading, data } = useQuery(GET_TENANT_THEME, {
    variables: { tenantId },
    skip: !tenantId || tenantId === "unknown",
  });

  // We want to await the results of the Tenant Domain lookup BEFORE we load the theme.
  // If no known Tenant has been found after lookup, then set the theme to the default settings.
  // Load theme settings from DB once TenantID has been fetched.
  useMemo(() => {
    if (tenantId === "unknown") {
      const themeValues = GENERIC_FUSESPORT_THEME;
      setTheme(themeValues);
    }
    if (!loading && data) {
      const values = get(data, "TenantTheme_by_pk", null);
      const themeValues = {
        logo_path: get(values, "FileLogo.url", GENERIC_FUSESPORT_THEME.logo_path),
        login_logo_path: get(values, "FileLoginLogo.url", GENERIC_FUSESPORT_THEME.login_logo_path),
        siteName: get(values, "siteName", GENERIC_FUSESPORT_THEME.siteName),
        loginPretext: get(values, "loginPretext", GENERIC_FUSESPORT_THEME.loginPretext),
        cmEventListBanner: get(values, "FileEventListBanner.url", GENERIC_FUSESPORT_THEME.cmEventListBanner),
        cmEventBanner: get(values, "FileEventBanner.url", GENERIC_FUSESPORT_THEME.cmEventBanner),
        cmSportMeetBanner: get(values, "FileSportMeetBanner.url", GENERIC_FUSESPORT_THEME.cmSportMeetBanner),
        privacyPageLink: get(values, "FilePrivacyPolicy.url", GENERIC_FUSESPORT_THEME.privacyPageLink),
        termsPageLink: get(values, "FileTermsAndConditions.url", GENERIC_FUSESPORT_THEME.termsPageLink),
        termsConditionsAcceptanceStatement: get(
          values,
          "termsConditionsAcceptanceStatement",
          GENERIC_FUSESPORT_THEME.termsConditionsAcceptanceStatement,
        ),
      };
      setTheme(themeValues);
    }
  }, [loading, data, tenantId]);

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
}

ThemeContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
